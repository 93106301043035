<script>
import TableauCommun from '@/components/shared/tableauCommun'
import PageHeader from '@/components/shared/pageHeader'
import PriceFormat from '@/mixins/priceFormat'

export default {
  name: 'Commandes',
  mixins: [PriceFormat],
  components: {
    TableauCommun,
    PageHeader
  },
  data () {
    return {
      listAchat: [],
      commandeTotal: 0,
      loading: true,
      limit: 25,
      page: 1
    }
  },
  apollo: {
    commandes: {
      query: require('@/graphql/queries/v2/boutiqueCommandes.gql'),
      variables () {
        return {
          page: this.page,
          limit: this.limit
        }
      },
      update (data) {
        if (this.page === 1) {
          this.listAchat = data.boutiqueCommandes.resultat
          this.commandeTotal = data.boutiqueCommandes.total
        } else {
          this.listAchat.push(...data.boutiqueCommandes.resultat)
        }
        this.loading = false
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    formatedAchats () {
      return this.listAchat.map((achat, index) => {
        achat.createdAt = this.$moment(achat.createdAt).format('DD/MM/YYYY')
        achat.total = this.priceFormat(achat.total)
        return achat
      })
    },
    tableauHeaders () {
      return [
        { text: this.$t('commandes.titre'), value: 'titre', sortable: true },
        { text: this.$t('commandes.nb-items'), value: 'commandesItems.length', sortable: false },
        { text: this.$t('commandes.date'), value: 'createdAt' },
        { text: this.$t('commandes.montant'), value: 'total' },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  }
}
</script>

<template lang="pug">
.commandes
  page-header(:title="$t('menu.mes-commandes')", :backButtonLabel="$t('menu.home')", :backButtonTo="{ name: 'home___' + $i18n.locale, params: { lang: $i18n.locale } }")
  .content.mt3
    section.fullsize-section.animated.fadeIn
      tableau-commun(
        :loading='loading',
        :nbTotal='commandeTotal',
        :items='formatedAchats',
        :headers='tableauHeaders',
        :routeName="'commandeDetail'"
      )
</template>
